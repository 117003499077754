import React from 'react'
import Layout from '../layouts'
import ServeBanner from '../images/slide2.jpg'
import Bar from '../images/bar-chart.png'
import Money from '../images/money.png'
// import Conversion from '../images/conversation.png'
import Collab from '../images/collaboration.png'
import Plan from '../images/planning.png'
import { Link } from 'gatsby'

const AboutPage = () => (
	<Layout head={{ title: 'Services' }}>
		<div className="srvcs">
			<div className="contact">
				<div
					id="hero-section"
					className="bg-cover"
					style={{ background: `url("${ServeBanner}")` }}
				>
					<div className="container">
						<div className="slim-content">
							<h1 className="text-red">How We Help</h1>
							<p className="py-4">
								A comprehensive suite of services with one mission to rapidly
								grow your sales.
							</p>
						</div>
					</div>
				</div>
				<div className="container py-5">
					<div className="row">
						<div className="col-md-4">
							<div className="service-slide">
								<div className="service-img">
									<img src={Plan} className="img-fluid" alt="planning" />
								</div>
								<h4>Sales Growth Assessment</h4>
								<p>
									Do you know why your sales are stagnant or declining? Do you
									have clear insights and a plan to rapidly grow and scale
									sales? We do!
								</p>
								<Link to="//sales-growth-assessment" className="red-link">
									Learn More
								</Link>
								{/*<h4>On-Site Sales Assessment</h4>
								<p>
									A comprehensive evaluation of your readiness to meet your
									sales objectives, followed by actionable, prioritized
									recommendations to improve sales productivity.
								</p>
								<Link to="/on-site-sales" className="red-link">
									Learn More
								</Link>
								*/}
							</div>
						</div>

						<div className="col-md-4">
							<div className="service-slide">
								<div className="service-img">
									<img src={Collab} className="img-fluid" alt="collaboration" />
								</div>
								<h4>ProGrowth Rapid Growth Sales Plan</h4>
								<p>
									Let's create a comprehensive plan that provides you with all
									the tools you need to find and hire the right salespeople,
									build the appropriate sales infrastructure and effectively
									manage your sales team.
								</p>
								<Link
									to="/progrowth-rapid-growth-sales-plan"
									className="red-link"
								>
									Learn More
								</Link>
							</div>
						</div>

						<div className="col-md-4">
							<div className="service-slide">
								<div className="service-img">
									<img src={Bar} className="img-fluid" alt="bar" />
								</div>
								<h4>Outsourced / Fractional VP of Sales / CRO</h4>
								<p>
									You need to run your business; let our Sales Consultants run
									your sales team. We create a high-impact sales culture to
									maximize sales at a fraction of the cost of a full-time VP of
									Sales.
								</p>
								<Link to="/outsourced-vp-of-sales" className="red-link">
									Learn More
								</Link>
							</div>
						</div>

						<div className="col-md-4">
							<div className="service-slide">
								<div className="service-img">
									<img src={Money} className="img-fluid" alt="money" />
								</div>
								<h4>Certified Sales Mentoring and Coaching</h4>
								<p>
									Genesis+® is a powerful 16-week sales training and
									certification program that builds and prepares sales teams for
									rapid growth.
								</p>
								<Link
									to="/certified-sales-mentoring-and-coaching"
									className="red-link"
								>
									Learn More
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default AboutPage
